import classnames from 'classnames';
import * as React from 'react';
import { customCssClasses } from '@wix/editor-elements-common-utils';
import semanticClassNames from '../../../Container.semanticClassNames';
import {
  IContainerProps,
  IContainerImperativeActions,
} from '../../../Container.types';
import { ContainerLogic } from '../../shared/ContainerLogic';
import styles from './RibbonAreaSkin.scss';

const RibbonAreaSkin: React.ForwardRefRenderFunction<
  IContainerImperativeActions,
  IContainerProps
> = (props, ref) => {
  return (
    <ContainerLogic
      {...props}
      ref={ref}
      className={classnames(styles.root, props.className)}
      renderSlot={({ containerChildren }) => (
        <>
          <div className={styles.flRibbon} />
          <div className={styles.frRibbon} />
          <div
            className={classnames(
              styles.bg,
              customCssClasses(
                ...(props.customClassNames || []),
                semanticClassNames.root,
              ),
            )}
          />
          {containerChildren}
        </>
      )}
    />
  );
};

export default React.forwardRef(RibbonAreaSkin);
