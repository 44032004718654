import Container_RibbonAreaSkinComponent from '@wix/thunderbolt-elements/src/components/Container/viewer/skinComps/RibbonAreaSkin/RibbonAreaSkin.skin';


const Container_RibbonAreaSkin = {
  component: Container_RibbonAreaSkinComponent
};


export const components = {
  ['Container_RibbonAreaSkin']: Container_RibbonAreaSkin
};

